<!-- 内化率 -->
<template>
  <div>
    <el-button @click="goUpperLevel" style="margin-bottom:20px">
      <i class="el-icon-back" style="margin-right:10px"></i>返回
    </el-button>
    <el-card class="box-card">
      <span class="span-title">{{K12Histogram.name}}-班级内化率</span>
      <el-table :data="tableData.list" style="width: 100%">
        <el-table-column prop="recordTime" label="时间(min)"></el-table-column>
        <el-table-column prop="specialNmae" label="专题名称"></el-table-column>
        <el-table-column prop="caseSN" label="变式名称"></el-table-column>
        <el-table-column prop="accuracy" label="内化率"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pagesize"
        :total="tableData.total"
        @current-change="changeCurrentPage"
      ></el-pagination>
      <span class="span-title">
        {{activeName}}--班级分析
        <el-select v-model="activeValue" placeholder="请选择" size="mini" @change="selectActive">
          <el-option
            v-for="item in activeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span>
          用时及格线
          <el-input v-model="Xinput" placeholder="请输入X轴" @blur="selectEcharts" size="mini"></el-input>内化率及格线
          <el-input v-model="Yinput" placeholder="请输入Y轴" @blur="selectEcharts" size="mini"></el-input>
        </span>
      </span>

      <div ref="variant" class="variant-echarts" :style="{width: '100%', height: '500px'}"></div>
      <span class="span-title">班级变式平均内化统计分析</span>
      <div ref="internalization" :style="{width: '100%', height: '500px'}"></div>
    </el-card>
  </div>
</template>

<script>
import { studentByIdAnalysis } from '../../api/api'
import { activeDetails } from '../../api/api'
import { ClassNPCasesAccuracy } from '../../api/api'

export default {
  data () {
    return {
      tableData: [],//表格数据
      chart: null,//图表实例
      internalizationChart: null,
      currentPage: 1,//页码
      pagesize: 5,//条数
      K12Histogram: {},//班级内化率
      activeValue: '',//选中的活动
      activeOptions: [],//活动集合
      activeName: '',//活动名字
      Xinput: '',//x轴数据
      Yinput: '50',//Y轴数据
      internalizationRate: []//图表数据
    }
  },

  components: {},
  mounted () {
    this.nhEcharts()
    this.loadStudentByIdAnalysis()
    this.K12Histogram = JSON.parse(this.$route.query.searchInfo)
    this.loadActiveDetails()
  },
  methods: {
    // 选中变式
    selectActive (val) {
      this.activeOptions.map(item => {
        if (val == item.value) {
          this.activeName = item.label
        }
      })
      this.loadTreeSpecial()
    },
    // 加载该活动下的变式
    loadActiveDetails () {
      let param = {
        'id': JSON.parse(this.$route.query.searchInfo).id
      }
      activeDetails(param).then(res => {
        let arr = []
        res.data.stems.map(item => {
          let obj = {}
          obj['value'] = item.caseId
          obj['label'] = item.stemName
          arr.push(obj)
        })
        this.activeName = arr[0].label
        this.activeValue = arr[0].value
        this.activeOptions = arr
        this.loadTreeSpecial()
      })
    },
    // 获取内化率时间图表数据
    loadTreeSpecial () {
      let param = {
        "activeId": JSON.parse(this.$route.query.searchInfo).id,
        "classId": JSON.parse(this.$route.query.searchInfo).classId,
        "id": this.activeValue
      }
      ClassNPCasesAccuracy(param).then(res => {
        if (res.data) {
          let arr = []
          res.data.map(item => {
            let arr1 = []
            arr1.push((item.accuracy * 100).toFixed(2))
            arr1.push((item.recordTime / 60).toFixed(2))
            arr1.push(item.fullName)
            arr.push(arr1)
          })
          this.internalizationRate = arr
          this.internalizationRate_Y = (Number(this.internalizationRate[0][1]) + 0.1).toFixed(2)
          this.nhEcharts()
          let rangeY = this.chart.getModel().getComponent('yAxis').axis.scale._extent;
          this.Xinput = (rangeY[1] / 2).toFixed(2)
          this.selectEcharts()
        }
      })
    },
    // 选择页码
    changeCurrentPage (val) {
      this.currentPage = val
      this.loadStudentByIdAnalysis()
    },
    loadStudentByIdAnalysis () {
      let params = JSON.parse(this.$route.query.searchInfo)
      this.className = params.className
      let param = {
        "pageNum": this.currentPage,
        "pageSize": this.pagesize,
        "classId": params.classId,
        'activeId': params.id
      }
      studentByIdAnalysis(param).then(res => {
        res.data.list.map(item => {
          if (item.stemType == 0) {
            item.stemType = 'K12'
          }
          item.accuracy = (item.accuracy * 100).toFixed(2)
        })
        let arrY2 = []
        let arrY1 = []
        let arrX = []
        res.data.list.forEach((item, index) => {
          item.recordTime = (item.recordTime / 60).toFixed(2)
          arrX.push(item.caseSN)
          arrY1.push(item.recordTime)
          arrY2.push(item.accuracy)
        })
        this.internalization(arrX, arrY1, arrY2)
        this.tableData = res.data
        this.tableData.list.map(item => {
          item.accuracy = item.accuracy + '%'
        })
      })
    },
    // 输入数据
    selectEcharts () {
      this.nhEcharts()
    },
    nhEcharts () {
      this.chart = this.$echarts.init(this.$refs.variant)
      var option;

      option = {
        tooltip: {
          /*返回需要的信息*/
          formatter: function (param) {
            var value = param.value;
            return '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 16px;"> ' + value[2] + '(' + value[0] + '%' + ')' + '<div>' + '用时: ' + value[1] + '(min)' + '</div>' +
              '</div>';
          }
        },
        xAxis: {
          name: '内化率(%)',
          max: 100
        },
        yAxis: {
          name: '时间(/min)',
          max: this.internalizationRate_Y
        },
        series: [{
          symbolSize: 20,
          data: this.internalizationRate,
          type: 'scatter',
          markLine: {
            tooltip: {
              showContent: false,
            },
            itemStyle: {
              normal: {
                lineStyle: { type: 'solid', color: 'blue' }
                , label: { show: true, position: 'left' }
              }
            },
            data: [{
              name: '用时及格线',
              yAxis: this.Xinput
            }, {
              name: '内化率及格线',
              xAxis: this.Yinput
            }]
          }
        }]
      };
      this.chart.setOption(option);
    },
    internalization (arrX, arrY1, arrY2) {
      this.internalizationChart = this.$echarts.init(this.$refs.internalization)
      var colors = ['#5470C6', '#91CC75'];
      var option;
      option = {
        color: colors,

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          right: '20%'
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['平均用时', '平均内化率']
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              formatter: function (value) {
                var ret = "";//拼接加\n返回的类目项  
                var maxLength = 15;//每项显示文字个数  
                var valLength = value.length;//X轴类目项的文字个数  
                var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数  
                if (rowN > 1)//如果类目项的文字大于3,  
                {
                  for (var i = 0; i < rowN; i++) {
                    var temp = "";//每次截取的字符串  
                    var start = i * maxLength;//开始截取的位置  
                    var end = start + maxLength;//结束截取的位置  
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧  
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串  
                  }
                  return ret;
                }
                else {
                  return value;
                }
              }
            },
            data: arrX
          }
        ],
        yAxis: [
          {
            name: '平均用时(min)',
            splitLine: {
              show: false
            },
            axisTick: {
              //y轴刻度线
              show: true
            },
            axisLine: {
              show: true, // Y轴
            },
          },
          {
            name: '平均内化率(%)',
            max: 100,
            splitLine: {
              show: false
            },
            axisTick: {
              //y轴刻度线
              show: true
            },
            axisLine: {
              show: true, // Y轴
            },
          }
        ],
        series: [
          {
            name: '平均用时',
            type: 'bar',
            yAxisIndex: 0,
            data: arrY1,
          },
          {
            name: '平均内化率',
            type: 'bar',
            yAxisIndex: 1,
            data: arrY2
          }
        ]
      };
      this.internalizationChart.setOption(option);
    },
    // 返回上一级
    goUpperLevel () {
      this.$router.go(-1)
    },
    // 时间转换
    secondsFormat (s) {
      var day = Math.floor(s / (24 * 3600)); // Math.floor()向下取整
      var hour = Math.floor((s - day * 24 * 3600) / 3600);
      var minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60);
      var second = s - day * 24 * 3600 - hour * 3600 - minute * 60;
      if (hour) {
        return hour + ":" + minute + ":" + second;
      } else {
        if (second < 10) {
          if (minute < 10) {
            return "0" + minute + ":" + second + "0";
          } else {
            return minute + ":" + second + "0";
          }
        } else {
          return minute + ":" + second;
        }
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
.box-card {
  position: relative;
  .span-title {
    position: relative;
    font-weight: 700;
    font-size: 18px;
    display: block;
    margin-top: 30px;
    span {
      display: block;
      margin-top: 20px;
      font-size: 12px;
    }
  }
  .el-pagination {
    position: absolute;
    margin-top: 10px;
    right: 0;
  }
  .el-select {
    position: absolute;
    margin-left: 20px;
  }
  /deep/.el-input {
    display: inline-block;
    width: 200px;
  }
}
</style>
